//@flow
import React from 'react';

type State = {
  +mainTitle: string,
  +subTitle: string
};

type Payload = {
  mainTitle: string,
  subTitle: string
};

export default {
  state: { mainTitle: '', subTitle: '' },
  reducers: {
    changeTitle: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        mainTitle: payload.mainTitle,
        subTitle: payload.subTitle
      });
    }
  }
};
