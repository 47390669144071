import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';

type Props = {
  mainTitle?: string,
  subTitle: string,
  canBack: boolean
};

class TopoTitleComponente extends PureComponent<Props, State> {
  componentDidMount = () => {
    this.props.changePageTitle({
      subTitle: this.props.subTitle,
      mainTitle: this.props.mainTitle,
      canBack: this.props.canBack
    });
  };
  componentWillReceiveProps(nexProps) {
    this.props.changePageTitle({
      subTitle: nexProps.subTitle,
      mainTitle: nexProps.mainTitle,
      canBack: nexProps.canBack
    });
  }
  render() {
    return null;
  }
}
const mapDispatch = ({ pageTitle: { changePageTitle } }) => ({
  changePageTitle: pageTitle => changePageTitle(pageTitle)
});

export default connect(
  null,
  mapDispatch
)(TopoTitleComponente);
