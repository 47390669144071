import React, { PureComponent } from 'react';

export class BodyWhite extends PureComponent {
  componentDidMount() {
    document.body.classList.add('bodyWhite');
  }
  componentWillUnmount() {
    document.body.classList.remove('bodyWhite');
  }

  render() {
    return null;
  }
}
